import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/docs/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "environmentaccess"
    }}>{`Environment/Access`}</h1>
    <p>{`The NOD platform is designed to have high availability using extensive redundancy. The entire system can be set up with 100% failover between load balanced nodes in a virtualized environment. The individual PTO's need for accessibility of the NOD platform must be agreed with Entur.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      